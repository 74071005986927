
<template>
  <div>
    <Nav/>

    <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
      <div v-show="feedback" class="alert alert-danger" v-html="feedback"></div>

      <h1>{{ heading }}</h1>

      <p>
        An account is required to use CDI's web services and apps. After registering, a CDI employee will
        activate your account once it has been reviewed. Please note our
        <a href="/privacypolicy" target="_blank">Privacy Policy.</a>
      </p>

      <p>What role best describes you?</p>
      <div>
        <label class="radio-option">
          <input type="radio" name="contractor" value="1"  @click="setContractor(1)"> Contractor
        </label>
        <label class="radio-option">
          <input type="radio" name="contractor" value="0" @click="setContractor(0)"> Distributor
        </label>
        <label class="radio-option">
          <input type="radio" name="contractor" value="0" @click="setContractor(0)"> OEM
        </label>
        <label class="radio-option">
          <input type="radio" name="contractor" value="0" @click="setContractor(0)"> Rep Group
        </label>
      </div>
      <form v-if="showForm" @submit.prevent="validate(false)">
        <div class="body_content">
          <div v-show="isLive && isStaff" class="form-group">
            <label>
              <span>*</span> Record Type
            </label>
            <select class="form-control" v-model="formType" @change="validate(true)">
              <option v-for="[key, label] in this.userTypes" v-bind:key="key" :value="key">{{ label }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>
              <span v-show="!isContactForm">*</span>
              Email
            </label>
            <input type="text" v-model="formFields.email.val" @blur="validateEmail" class="form-control">
            <span v-show="formFields.email.error">
                            <label class="error">This field is required.</label>
                        </span>
            <span v-show="formFields.email.typeError">
                            <label class="error">Please enter a valid email address.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>
              <span>*</span>
              First Name
            </label>
            <input type="text" v-model="formFields.firstName.val" @blur="validateField(formFields.firstName)" class="form-control">
            <span v-show="formFields.firstName.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>
              <span>*</span>
              Last Name
            </label>
            <input type="text" v-model="formFields.lastName.val" @blur="validateField(formFields.lastName)" class="form-control">
            <span v-show="formFields.lastName.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>Title</label>
            <input type="text" v-model="formFields.title.val" class="form-control">
          </div>
          <div class="form-group">
            <label>
              <span v-show="!isContactForm">*</span> Address
            </label>
            <input type="text" v-model="formFields.address.val" @blur="validateField(formFields.address)" class="form-control">
            <span v-show="formFields.address.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>
              <span v-show="!isContactForm">*</span> City
            </label>
            <Typeahead cdi_type="city"
                       @valueChange="captureTypeAheadText"
                       :acceptPlainText="true"
                       @objectSelected="captureTypeAheadText"
                       :inputAttributes="{class: 'required website_user_required', id: 'city'}"
            />
            <span v-show="formFields.city.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>
              <span v-show="!isContactForm">*</span> State
            </label>
            <select class="form-control" v-model="formFields.state.val" @change="validateField(formFields.state)">
              <option value="0">None Selected</option>
              <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
            </select>
            <span v-show="formFields.state.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>
              <span v-show="!isContactForm">*</span>
              Zip
            </label>
            <input type="text" v-model="formFields.zip.val" @blur="validateField(formFields.zip)" class="form-control">
            <span v-show="formFields.zip.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>
              <span v-show="!isContactForm">*</span>
              Phone
            </label>
            <input type="text" v-model="formFields.phone.val" @blur="validateField(formFields.phone)" class="form-control">
            <span v-show="formFields.phone.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div class="form-group">
            <label>Mobile</label>
            <input type="text" v-model="formFields.mphone.val" class="form-control">
          </div>
          <div v-show="isChecked" class="form-group">

            <label><span>*</span> Which Roof Top Unit brands do you use on change outs?
              Select all that apply</label>
            <div class="checkbox-grid">
              <div v-for="[key, label] in cache.contractorQuestionsCache.Q1" :key="key">
                <label><input type="checkbox" v-model="formFields.selectedBrandsAll.val[key]" :value="key" @change="validateField(formFields.selectedBrandsAll)">{{ label }}</label>
              </div>
            </div>
            <span v-show="formFields.selectedBrandsAll.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div v-show="isChecked" class="form-group">
            <label><span>*</span> Roughly how many RTUs do you change out annually?</label>
            <div>
              <label class="radio-option">
                <input type="radio" v-model="formFields.selectedRTUCount.val" value="1"> Less than 50
              </label>
              <label class="radio-option">
                <input type="radio" v-model="formFields.selectedRTUCount.val" value="2"> 50 - 200
              </label>
              <label class="radio-option">
                <input type="radio" v-model="formFields.selectedRTUCount.val" value="3"> 201 - 500
              </label>
              <label class="radio-option">
                <input type="radio" v-model="formFields.selectedRTUCount.val" value="4"> More than 500
              </label>
            </div>

            <span v-show="formFields.selectedRTUCount.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div v-show="isChecked" class="form-group">
            <label><span>*</span> CDI offers a measurement service; would this be helpful to you?</label>

            <div>
              <label class="radio-option">
                <input name="selectedMeasureService" type="radio" v-model="formFields.selectedMeasureService.val" value="1"> Yes
              </label>

              <label class="radio-option">
                <input name="selectedMeasureService" type="radio" v-model="formFields.selectedMeasureService.val" value="2"> No
              </label>
            </div>
            <span v-show="formFields.selectedMeasureService.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div v-show="!isChecked" class="form-group">
            <label>
              <span v-show="!isContactForm && !isEmployeeForm">*</span> Brand
            </label>
            <select class="form-control" v-model="formFields.brand.val" @change="validateField(formFields.brand)">
              <option v-for="[key, label] in cache.brandCache.BRANDS" v-bind:key="key" :value="key">{{ label }}</option>
            </select>
            <span v-show="formFields.brand.error">
                            <label class="error">This field is required.</label>
                        </span>
          </div>
          <div v-show="!isContactForm" class="form-group">
            <label>*Password</label>
            <input type="password" v-model="formFields.password.val" @blur="validatePassword" class="form-control" minlength=8>
            <span v-show="formFields.password.error">
                            <label class="error">This field is required.</label>
                        </span>
            <span v-show="formFields.password.typeError">
                            <label class="error">Please enter at least 8 characters.</label>
                        </span>
          </div>
          <div v-show="!isContactForm" class="form-group">
            <label>*Password (again)</label>
            <input type="password" v-model="formFields.passwordConfirm.val" @blur="validatePasswordConfirm" class="form-control" minlength=8>
            <span v-if="formFields.passwordConfirm.error">
                            <label class="error">This field is required.</label>
                        </span>
            <span v-else-if="formFields.passwordConfirm.typeError">
                            <label class="error">Please enter at least 8 characters.</label>
                        </span>
            <span v-else-if="formFields.passwordConfirm.typeErrorMatch">
                            <label class="error">Please enter the same value again.</label>
                        </span>
          </div>
        </div>

        <div v-show="!isStaff">
          <label><input type="checkbox" name="params[terms_date]" v-model="formFields.termsDate.val"
                        @change="validateField(formFields.termsDate)"> I, on behalf of me and/or my company,
            hereby acknowledge that I have read and understand CDI's terms and conditions as listed on CDI's
            website and agree to be bound by all of the provisions of the same. At this time we will only
            require periodic acknowledgement if there is a change in terms.</label>
          <span v-show="formFields.termsDate.error">
                        <label class="error">This field is required.</label>
                    </span>
        </div>

        <br><br>
        <input type="submit" :value="`${heading}`" class="btn btn-primary btn-lg">
        <br><br>
      </form>

      <div v-show="!isContactForm && this.showForm" class="terms_box">
        <TermsAndConditions/>
      </div>
    </main>
  </div>
</template>

<script>
import {myStore} from '@/store/My.store';
import Nav from "@/components/my/MyNav";
import TermsAndConditions from "@/components/my/TermsAndConditions";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import stateCache from "@/cache/state.cache";
import brandCache from "@/cache/brand.cache";
import helpers from "@/store/helpers";
import contractorQuestionsCache from "@/cache/contractorQuestions.cache";

export default {
  name: "Register",
  components: {
    Typeahead,
    TermsAndConditions,
    Nav
  },
  data() {
    return {
      isChecked: false,
      showForm: false,
      state: myStore.state,
      cache: {
        stateCache,
        brandCache,
        contractorQuestionsCache
      },
      feedback: null,
      userTypes: [
        [this.$cdiVars.USER_TYPE_NEW_USER, 'New User'],
        [this.$cdiVars.USER_TYPE_DISTRIBUTOR, 'Customer'],
        [this.$cdiVars.USER_TYPE_EMPLOYEE, 'Employee'],
        [this.$cdiVars.USER_TYPE_CONTACT, 'Prospect'],
      ],
      formType: this.$cdiVars.USER_TYPE_NEW_USER,
      formFields: {
        email: {
          val: '',
          qsField: '[email]',
          normalRequired: true,
          error: false,
          typeError: false
        },
        firstName: {
          val: '',
          qsField: '[first_name]',
          normalRequired: true,
          prospectRequired: true,
          error: false
        },
        lastName: {
          val: '',
          qsField: '[last_name]',
          normalRequired: true,
          prospectRequired: true,
          error: false
        },
        title: {
          val: '',
          qsField: '[job_title]'
        },
        address: {
          val: '',
          qsField: '[address][address]',
          normalRequired: true,
          error: false
        },
        city: {
          val: '',
          qsField: '[address][city]',
          normalRequired: true,
          error: false
        },
        state: {
          val: 0,
          qsField: '[address][state]',
          normalRequired: true,
          error: false
        },
        zip: {
          val: '',
          qsField: '[address][zip]',
          normalRequired: true,
          error: false
        },
        phone: {
          val: '',
          qsField: '[phone]',
          normalRequired: true,
          error: false
        },
        mphone: {
          val: '',
          qsField: '[mphone]'
        },
        brand: {
          val: 60,
          qsField: '[brand_id]',
          normalRequired: true,
          error: false
        },
        password: {
          val: '',
          qsField: '[passwd]',
          normalRequired: true,
          error: false,
          typeError: false
        },
        passwordConfirm: {
          val: '',
          qsField: '[passwd2]',
          normalRequired: true,
          error: false,
          typeError: false,
          typeErrorMatch: false
        },
        termsDate: {
          val: 0,
          qsField: '[terms_date]',
          normalRequired: this.isRequired,
          error: false
        },
        selectedBrandsAll: {
          val: [],
          qsField: '[selected_brands_all]',
          normalRequired: true,
          error: false
        },
        selectedRTUCount: {
          val: 1,
          qsField: '[selected_rtu_count]',
          normalRequired: true,
          error: false
        },
        selectedMeasureService: {
          val: 1,
          qsField: '[selected_measure_service]',
          normalRequired: true,
          error: false
        },
        isContractor: {
          val: 0,
          qsField: '[is_contractor]',
          normalRequired: false,
          error: false
        }
      }
    }
  },
  methods: {
    submit: function () {
      let options = this.formFields;
      options.userType = {
        val: this.formType,
        qsField: '[user_type_id]'
      };
      options.termsDate.val = 'NOW()';
      this.feedback = null;

      myStore.api('myuser/save_new', helpers.getQSPairs(this.formFields)).then(data => {
        this.feedback = data.msg;

        if (data.result && !this.isLive) {
          this.$appStore.signIn(this.formFields);
        }

        window.scrollTo(0, 0);
      });
    },
    validate: function (noSubmit = false) {
      this.resetErrors();

      let valid = true;
      let fields = this.fieldsUsed();
      fields.forEach(field => {
        let key = field[0];
        let details = field[1];

        if (Array.isArray(details.val) && details.val.length <= 0) {
          this.formFields[key].error = true;
          valid = false;
        }

        if (!details.val || details.val === '') {
          this.formFields[key].error = true;

          valid = false;
        }
      });

      if (valid && !noSubmit) {
        this.submit();
      }

      window.scrollTo(0, 0);
    },
    fieldsUsed: function () {
      let fields = this.regularFormFields;

      if (this.isContactForm) {
        fields = this.prospectFormFields;
      }

      return fields;
    },
    validateField: function (object) {
      object.error = !object.val || object.val === '';
    },
    validateEmail: function () {
      if (!this.isContactForm) {
        this.validateField(this.formFields.email);
      }

      this.formFields.email.typeError = !this.$appStore.validateEmail(this.formFields.email.val);
    },
    validatePassword: function () {
      this.validateField(this.formFields.password);
      this.formFields.password.typeError = this.formFields.password.val.length < 8;
    },
    validatePasswordConfirm: function () {
      this.validateField(this.formFields.passwordConfirm);
      this.formFields.passwordConfirm.typeError = this.formFields.passwordConfirm.val.length < 8;
      this.formFields.passwordConfirm.typeErrorMatch = this.formFields.passwordConfirm.val !== this.formFields.password.val;
    },
    captureTypeAheadText: function (obj) {
      this.formFields.city.val = obj.value;
      this.validateField(this.formFields.city);
    },
    resetErrors: function () {
      Object.entries(this.formFields).forEach(field => field[1].error = false);
    },
    setContractor: function (val){
      this.showForm = true;
      if (val == 1){
        this.isChecked = true;
        this.formFields.isContractor.val = val;
        this.formFields.selectedBrandsAll.normalRequired = true;
        this.formFields.selectedRTUCount.normalRequired = true;
        this.formFields.selectedRTUCount.val = 0;
        this.formFields.selectedMeasureService.val = '';
      }else{
        this.isChecked = false;
        this.formFields.isContractor.val = 0;
        this.formFields.selectedBrandsAll.normalRequired = false;
        this.formFields.selectedRTUCount.normalRequired = false;
        this.formFields.selectedMeasureService.val = 1;
      }
    }
  },
  computed: {
    heading: function () {
      let text = 'Create Account';

      if (this.isStaff && this.isLive) {
        text = 'Create Record';
      }

      return text;
    },
    isLive: function () {
      return this.$appStore.state.loggedIn;
    },
    isStaff: function () {
      return this.$appStore.isStaff();
    },
    isContactForm: function () {
      return this.formType === this.$cdiVars.USER_TYPE_CONTACT;
    },
    isEmployeeForm: function () {
      return this.formType === this.$cdiVars.USER_TYPE_EMPLOYEE;
    },
    regularFormFields: function () {
      return Object.entries(this.formFields).filter(entry => {
        let details = entry[1];


        return details.normalRequired === true;
      });
    },
    prospectFormFields: function () {
      return Object.entries(this.formFields).filter(entry => {
        let details = entry[1];
        return details.prospectRequired === true;
      });
    },
    isRequired: function () {
      return !this.isStaff();
    }
  }
}
</script>

<style scoped>
.checkbox-grid {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
}

.checkbox-grid input[type="checkbox"] {
  margin-right: 5px;
}

.error {
  margin-right: 5px;
}

.terms_box {
  height: 300px;
  overflow: auto;
}
.radio-option {
  display: block;
  margin-bottom: 10px;
}
</style>